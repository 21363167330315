import React from 'react'
import { connect } from 'react-redux'
import { getClientVehicles } from '../../context/selectors/clientVehiclesSelectors'
import { t } from '../../i18n'
import { Vehicle } from '../../types/Vehiculo'
import InputCestaDropDown from '../inputCesta/InputCestaDropDown'
import styles from './asistente.module.scss'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import CestaLastRow from '../cesta/cestaItem/cestaLastRow/CestaLastRow'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import { IAppState } from '../../context/context'
import { selectPostCode } from '../../context/selectors/userSelectors'
import {
  findAireAcondicionado,
  setAireAcondicionadoPostCode,
  setAireAcondicionadoStep,
  setAireAcondicionadoVehicle,
} from '../../context/actions/aireAcondicionadoActions'

const UserVehiclesSchema = yup.object().shape({
  matricula: yup.string().required(t('validations.license.required')),
})
export interface UserVehiclesFields {
  matricula: string
}

interface Props {
  vehicles: Vehicle[]
  startJourney: (vehicle: Vehicle, postCode: string) => void
  showVehicleFinder: () => void
  postCode: string
}
const UserVehicles: React.FC<Props> = ({
  vehicles,
  showVehicleFinder,
  postCode,
  startJourney,
}) => {
  const { register, handleSubmit, errors } = useForm<UserVehiclesFields>({
    mode: 'onSubmit',
    validationSchema: UserVehiclesSchema,
  })

  const vehiclesOptions = vehicles.map((vehicle) => ({
    value: vehicle.cod_matricula,
    label: `${vehicle.cod_matricula} - ${vehicle.marca} - ${vehicle.modelo}`,
  }))

  const onSubmit = (data) => {
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle.cod_matricula === data.matricula
    )
    startJourney(selectedVehicle, postCode)
  }

  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
        }}>
        <InputCestaDropDown
          labelId="aire_acondicionado.asistente.tus_vehiculos"
          size="normal"
          formRef={register}
          name="matricula"
          errors={errors}
          options={vehiclesOptions}
          labelAbove={true}
        />
        <button onClick={showVehicleFinder} className={styles.add_vehicle}>
          + {t('aire_acondicionado.asistente.añadir_vehiculo')}
        </button>
        <div className={styles.cestaLastRowWrapper}>
          <CestaLastRow
            onContinuarClick={handleSubmit(onSubmit)}
            onBackClick={() => {
              navigate(route('coche.carga'))
            }}
          />
        </div>
      </form>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    vehicles: getClientVehicles(state),
    postCode: selectPostCode(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startJourney: (vehicle, postCode) => {
      dispatch(setAireAcondicionadoVehicle(vehicle))
      dispatch(setAireAcondicionadoPostCode(postCode))
      dispatch(findAireAcondicionado())
    },
    setStep: (step) => dispatch(setAireAcondicionadoStep(step)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserVehicles)
