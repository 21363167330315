import React, { useContext } from 'react'
import { setAltaVehiculoStep } from '../../context/actions/altaVehiculoActions'
import AppContext from '../../context/context'
import { t } from '../../i18n'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './VehicleFinderAireAcondicionado.module.scss'
import VehicleFinderStepFive from './vehicleFinderStepFive/VehicleFinderStepFive'
import VehicleFinderStepFour from './vehicleFinderStepFour/VehicleFinderStepFour'
import VehicleFinderStepSix from './vehicleFinderStepSix/VehicleFinderStepSix'
import VehicleFinderStepTwo from './vehicleFinderStepTwo/VehicleFinderStepTwo'
import { Vehicle } from '../../types/Vehiculo'

interface VehicleFinderAireAcondicionadoProps {
  onSelectVehicle: (vehicle: Vehicle) => void
  goToPreviousStep: () => void
}

export default function VehicleFinderAireAcondicionado({
  onSelectVehicle,
  goToPreviousStep,
}: VehicleFinderAireAcondicionadoProps) {
  const { altaVehiculoState, dispatch } = useContext(AppContext)
  const { step } = altaVehiculoState

  const goToStep = (step) => {
    dispatch(setAltaVehiculoStep(step))
  }
  return (
    <div className={styles.alta_vehiculo_container}>
      {step === 0 && null}
      {altaVehiculoState.loading && (
        <SpinnerWithText text={t('alta_vehiculo.cargando')} />
      )}
      {!altaVehiculoState.loading && step === 2 && (
        <VehicleFinderStepTwo
          origin="ac"
          goToStep={goToStep}
          revisionesSubmit={onSelectVehicle}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 4 && (
        <VehicleFinderStepFour
          origin="ac"
          goToStep={goToStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 5 && (
        <VehicleFinderStepFive
          goToStep={goToStep}
          goToNextStep={null}
          origin="ac"
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {!altaVehiculoState.loading && step === 6 && (
        <VehicleFinderStepSix origin="ac" />
      )}
    </div>
  )
}
