import { navigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { SeoData } from '../../types/Seo'
import {
  getClientVehicles,
  isClientVehiclesLoading,
} from '../../context/selectors/clientVehiclesSelectors'
import Layout from '../Layout'
import LayoutSelector from '../layouts/layoutSelector/LayoutSelector'
import { t } from '../../i18n'
import MainFormContainer from '../layouts/mainFormContainer/MainFormContainer'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import FunnelSideBar from '../funnelSideBar/FunnelSideBar'
import { Vehicle } from '../../types/Vehiculo'
import { findClientVehicles } from '../../context/actions/clientVehiclesActions'
import UserVehicles from './UserVehicles'
import styles from './asistente.module.scss'
import { IAppState } from '../../context/context'
import MatriculaForm from './MatriculaForm'
import VehicleFinderAireAcondicionado from '../vehicleFinder/VehicleFinderAireAcondicionado'
import {
  getAireAcondicionadoStep,
  isAireAcondicionadoLoading,
} from '../../context/selectors/aireAcondicionadoSelectors'
import {
  findAireAcondicionado,
  setAireAcondicionadoDevice,
  setAireAcondicionadoStep,
  setAireAcondicionadoVehicle,
} from '../../context/actions/aireAcondicionadoActions'
import { resetAltaVehiculos } from '../../context/actions/altaVehiculoActions'
import Size from '../../utils/media-queries-setup'
import { Device } from '../../types/Device'
import { setCitaPreviaStep } from '../../context/actions/citaPreviaActions'
import { CitaPreviaEnum, citaPreviaSteps } from '../CitaPrevia/CitaPreviaSteps'

interface Props {
  seoData: SeoData
  fetchClientVehicles: () => void
  setStep: (step: number) => void
  setDevice: (device: Device) => void
  startJourney: (vehicle: Vehicle) => void
  resetVehicleFinder: () => void
  loading: boolean
  vehicles: Vehicle[]
  step: number
}

const Asistente: React.FC<Props> = ({
  seoData,
  fetchClientVehicles,
  setStep,
  setDevice,
  startJourney,
  resetVehicleFinder,
  loading,
  vehicles,
  step,
}) => {
  const dispatch = useDispatch()
  const [isVehicleFinder, setIsVehicleFinder] = useState(false)

  const device = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as Device

  useEffect(() => {
    fetchClientVehicles()
    setDevice(device)
  }, [fetchClientVehicles])

  useEffect(() => {
    setStep(1)
  }, [])

  useEffect(() => {
    setIsVehicleFinder(vehicles.length === 0)
  }, [vehicles])

  const showVehicleFinder = () => {
    setIsVehicleFinder(true)
  }

  const onGoBack = () => {
    setStep(step === 1 ? step : step - 1)
    if (step === 1) {
      dispatch(
        setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].step)
      )
      navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
    }
  }

  return typeof window === 'undefined' ? null : (
    <Layout seoData={seoData} selector={true} onGoBack={onGoBack}>
      <LayoutSelector
        hideSelectorSteps={true}
        stepActive={1}
        mobileStep={1}
        totalSteps={2}
        title={t('aire_acondicionado.asistente.title')}
        subTitle={t('aire_acondicionado.asistente.tu_vehiculo')}
        subTitle2={null}
        customSteps={[]}>
        <MainFormContainer>
          <div className={styles.asistente_ac}>
            <div className={styles.wrapper}>
              {loading ? (
                <SpinnerWithText
                  text={t('aire_acondicionado.asistente.cargando')}
                />
              ) : null}
              {!loading && step === 1 ? (
                <React.Fragment>
                  <p>
                    Indica tu matrícula para que podamos ayudarte en la elección
                    de la carga de AC más adecuada:
                  </p>
                  {!isVehicleFinder ? (
                    <UserVehicles showVehicleFinder={showVehicleFinder} />
                  ) : (
                    <MatriculaForm onGoBack={onGoBack} />
                  )}
                </React.Fragment>
              ) : null}
              {!loading && step === 2 ? (
                <VehicleFinderAireAcondicionado
                  onSelectVehicle={(vehicle) => {
                    startJourney(vehicle)
                    resetVehicleFinder()
                  }}
                  goToPreviousStep={onGoBack}
                />
              ) : null}
            </div>
          </div>
        </MainFormContainer>
        <FunnelSideBar />
      </LayoutSelector>
    </Layout>
  )
}

const mapStateToProps = (state: IAppState) => {
  return {
    loading:
      isClientVehiclesLoading(state) || isAireAcondicionadoLoading(state),
    vehicles: getClientVehicles(state),
    step: getAireAcondicionadoStep(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchClientVehicles: () => dispatch(findClientVehicles()),
    setStep: (step) => dispatch(setAireAcondicionadoStep(step)),
    setDevice: (device) => dispatch(setAireAcondicionadoDevice(device)),
    startJourney: (vehicle) => {
      dispatch(setAireAcondicionadoVehicle(vehicle))
      dispatch(findAireAcondicionado())
    },
    resetVehicleFinder: () => dispatch(resetAltaVehiculos()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Asistente)
