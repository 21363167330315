import React from 'react'
import useForm from 'react-hook-form'
import * as yup from 'yup'
import { IAppState } from '../../context/context'
import { t } from '../../i18n'
import InputCesta from '../inputCesta/InputCesta'
import styles from './asistente.module.scss'
import { matricula, normalizeMatricula } from '../../constants/matricula'
import { ValidateCP } from '../../../specific/helpers/validation/validationCP'
import { connect } from 'react-redux'
import CestaLastRow from '../cesta/cestaItem/cestaLastRow/CestaLastRow'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import { selectPostCode } from '../../context/selectors/userSelectors'
import { retrieveVehicleInfo } from '../../context/actions/altaVehiculoActions'
import {
  setAireAcondicionadoPostCode,
  setAireAcondicionadoStep,
} from '../../context/actions/aireAcondicionadoActions'

const elpaso = process.env.GATSBY_WEB === 'elpaso'

const CarInfoDataFields = {
  ...matricula,
  postCode: yup
    .string()
    .test(
      'test-codigopostal',
      t('validations.form_facturacion.postalCode.invalid'),
      (cp) => ValidateCP(cp)
    ),
}
interface InCarInfoDataFields {
  matricula: string
  postCode: string
}

const carInfoFormSchema = yup.object().shape(CarInfoDataFields)

interface Props {
  postCode: string | null
  setStep: (step: number) => void
  setPostCode: (postCode: string) => void
  startFindVehicleInfo: (matricula: string) => void
  onGoBack?: () => void
}
const MatriculaForm: React.FC<Props> = ({
  postCode,
  setStep,
  setPostCode,
  startFindVehicleInfo,
  onGoBack,
}) => {
  const defaultPostCode = elpaso ? '35001' : ''

  const { register, handleSubmit, errors } = useForm<InCarInfoDataFields>({
    mode: 'onSubmit',
    validationSchema: carInfoFormSchema,
  })

  const onSubmit = (data) => {
    data.matricula = normalizeMatricula(data.matricula)
    const { matricula, postCode } = data
    setPostCode(postCode)
    setStep(2)
    startFindVehicleInfo(matricula)
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputCesta
          label={t('aire_acondicionado.asistente.matricula')}
          name="matricula"
          defaultValue={''}
          refe={register}
          errors={errors}
          withInfoIcon
          infoIconText={t('aire_acondicionado.asistente.tooltip_matricula')}
        />
        <div className={styles.inputmessage}>
          {t('aire_acondicionado.asistente.con-matricula')}
        </div>
        <div className={postCode || elpaso ? styles.hide : ''}>
          <InputCesta
            label={t('aire_acondicionado.asistente.codigo_postal')}
            name="postCode"
            refe={register}
            errors={errors}
            defaultValue={postCode || defaultPostCode}
          />
        </div>
      </form>
      <div className={styles.cestaLastRowWrapper}>
        <CestaLastRow
          onContinuarClick={handleSubmit(onSubmit)}
          onBackClick={onGoBack}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: IAppState) => ({
  postCode: selectPostCode(state),
})

const mapDispatchToProps = (dispatch) => {
  return {
    startFindVehicleInfo: (matricula: string) =>
      dispatch(retrieveVehicleInfo(matricula)),
    setStep: (step: number) => dispatch(setAireAcondicionadoStep(step)),
    setPostCode: (postCode: string) =>
      dispatch(setAireAcondicionadoPostCode(postCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatriculaForm)
