import { graphql } from 'gatsby'
import React from 'react'
import { SeoData } from '../../../../../src/common/types/Seo'
import Asistente from '../../../../../src/common/components/aireAcondicionado/Asistente'

interface Props {
  data: {
    seoData: SeoData
  }
}

export default function AsistenteAireAcondicionado({ data }: Props) {
  return typeof window === 'undefined' ? null : (
    <Asistente seoData={data.seoData} />
  )
}

export const pageQuery = graphql`
  query asistenteAireAcondicionadoQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
