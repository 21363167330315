import codigosPostales from '../../constants/cp'


const CP_REGEX = /^(AD|[0-9]{2})[0-9]{3}$/

export const ValidateCP = function (cp) {
  if (cp.match(CP_REGEX)) {
    const provinciaCP = codigosPostales.find(codigoPostal =>
      cp.startsWith(codigoPostal.cp)
    )
    if (provinciaCP) return true
  }
  return false
}
