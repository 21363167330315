import { IAppState } from '../context'
import { ClientVehiclesState, key } from '../reducers/clientVehiclesReducer'
import { Vehicle } from '../../types/Vehiculo'

export const selectClientVehicles = (state: IAppState): ClientVehiclesState =>
  state[key] as ClientVehiclesState

export const isClientVehiclesLoading = (state: IAppState): boolean => {
  const clientVehiclesState = selectClientVehicles(state)
  return clientVehiclesState.loading
}

export const getClientVehicles = (state: IAppState): Vehicle[] => {
  const clientVehiclesState = selectClientVehicles(state)
  return clientVehiclesState.vehicles
}
